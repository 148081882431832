import { createSlice } from "@reduxjs/toolkit"
import { IJobListFilter } from "../../../shared/utils/interfaces/jobListFaces"
import { jobsPerPage } from "../../../shared/utils/constantData";
const listJobsSlice = createSlice({
    name: 'jobsList',
    initialState: {
        jobsListLength: 0,
        hasPublishJobs: false,
        totalJobCount: 0,
        jobsFilter: {
            page: 1,
            jobsPerPage: jobsPerPage,
            order: 'ASC',
            hideDrafts: false,
            shortVersion: true,
        }
    },
    reducers: {
        setJobsListLength: (state, action) => {
            state.jobsListLength = action.payload
        },
        setTotalJobCount: (state, action) => {
            state.totalJobCount = action.payload;
        },
        setJobsFilter: (state, action) => {
            state.jobsFilter = action.payload;
        },
        setHasPublishJobs: (state, action) => {
            state.hasPublishJobs = action.payload;
        },
    },
})

export const { setJobsListLength, setTotalJobCount, setJobsFilter, setHasPublishJobs } = listJobsSlice.actions

export default listJobsSlice.reducer

export const selectJobsLength = (state: { jobsList: { jobsListLength: number } }) => state.jobsList.jobsListLength;
export const selectFilters = (state: { jobsList: { jobsFilter: IJobListFilter } }) => state.jobsList.jobsFilter;
export const selectHasPublishJobs = (state: { jobsList: { hasPublishJobs: boolean } }) => state.jobsList.hasPublishJobs;
export const selectTotalJobCount = (state: { jobsList: { totalJobCount: number } }) => state.jobsList.totalJobCount;