import { createSlice } from "@reduxjs/toolkit"
import { Roles, User } from "../../../shared/utils/interfaces/AuthInterface"
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null, userOTPEmail: '', viewOTP: false, otp: '', isResetPassword: false, allRolesPermissions: [], rolePerCompany: null, rolePerJob: null as Roles | null, jobPermissions: [] as string[],
        isSuccessLogin: false,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { user } = action.payload
            state.user = user
        },
        setUserOTPEmail: (state, action) => {
            state.userOTPEmail = action.payload;
        },
        setOtpValue: (state, action) => {
            state.otp = action.payload
        },
        viewOTPComponent: (state, action) => {
            state.viewOTP = action.payload;
        },
        setIsResetPassword: (state, action) => {
            state.isResetPassword = action.payload
        },
        setAllRolesPermissions: (state, action) => {
            state.allRolesPermissions = action.payload;
        },
        setRolePerCompany: (state, action) => {
            state.rolePerCompany = action.payload;
        },
        setRolePermissionsPerJob: (state, action: { payload: { role: Roles | null, permissions: string[] } }) => {
            const { role, permissions } = action.payload
            state.rolePerJob = role
            state.jobPermissions = permissions
        },
        setIsSuccessLogin: (state, action) => {
            state.isSuccessLogin = action.payload;
        },
    },
})

export const { setCredentials, setUserOTPEmail, setOtpValue, viewOTPComponent, setIsResetPassword, setRolePermissionsPerJob, setRolePerCompany, setAllRolesPermissions, setIsSuccessLogin } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: { auth: { user: User } }) => state.auth.user
export const selectUserOTPEmail = (state: { auth: { userOTPEmail: string } }) => state.auth.userOTPEmail
export const selectViewOPT = (state: any) => state.auth.viewOTP;
export const selectOtpValue = (state: any) => state.auth.otp;
export const selectIsResetPassword = (state: any) => state.auth.isResetPassword;
export const selectAllRolesPermissions = (state: { auth: { allRolesPermissions: { [key: string]: { id: number, permissions: string[] } } } }) => state.auth.allRolesPermissions;
export const selectRolePerCompany = (state: { auth: { rolePerCompany: Roles.admin | Roles.superAdmin | null } }) => state.auth.rolePerCompany;
export const selectRolePerJob = (state: { auth: { rolePerJob: string } }) => state.auth.rolePerJob;
export const selectIsSuccessLogin = (state: { auth: { isSuccessLogin: boolean } }) => state.auth.isSuccessLogin;

