interface toggleProps {
    onToggle: () => void,
    active: boolean,
    icon: string,
    iconDark: string,
    text: string,
    className?: string
}
export default function ProfileToggleItem({ onToggle, active, icon, iconDark, text, className }: toggleProps) {
    return (
        <div
            onClick={onToggle}
            className={`${active ? "bg-gray-300 text-gray-500 border-gray-400" : ""}
            ${className ?? ''} flex justify-center items-center flex-col text-gray-300 w-28 h-14 md:h-28 border-gray-300 border-2 rounded md:p-5 cursor-pointer group hover:bg-gray-300 hover:text-gray-500 hover:border-gray-400`}
        >
            {active ?
                <img className='m-auto my-1' alt={text} src={iconDark} />
                :
                <>
                    <img className='m-auto my-1 group-hover:hidden' alt={text} src={icon} />
                    <img className='hidden m-auto my-1 group-hover:block' alt={text} src={iconDark} />
                </>
            }
            <span className="hidden md:inline">{text}</span>
        </div>
    )
}
