import { List } from "antd"
import ListItem from "../../../../../shared/components/List/ListItem"
import { useGetInterviewsQuestionsByCandidateIdQuery } from "../../../slice/candiddateApiSlice"
import { useSelector } from "react-redux"
import { selectCandidateProfile } from "../../../slice/candidateSlice"
import SkeletonList from "../../../../../shared/components/List/SkeletonList"
import { Warning } from "../../../../../shared/components/Warning/Warning"
import { errorMessage } from "../../../../../shared/utils/constantData"
import { EmptyList } from "../../../../../shared/components/List/EmptyList"

function InterviewQuestions() {
    const { id, interviewQuestions } = useSelector(selectCandidateProfile)
    const { data, isLoading, isSuccess, isError } = useGetInterviewsQuestionsByCandidateIdQuery(id, { skip: interviewQuestions.length })
    return (
        <>
            <p className='mb-3.5 text-gray-600 font-normal text-sm'>This is a list of tailored/proposed questions that could assist you in interviewing these candidates, based on AI analysis.</p>
            <hr />
            {interviewQuestions.length ?
                <List className="overflow-y-auto max-h-96">
                    {interviewQuestions.map((question: string, index: number) => {
                        return <ListItem
                            key={index}
                            title={question}
                            className='my-3 text-sm text-gray-600'
                        />
                    })}
                </List>
                : isLoading ? < SkeletonList isLoading={isLoading} hasLogoSkelton={false} />
                    : isError ? <Warning title={errorMessage} message='Please refresh to try again' btnText='Refresh' />
                        : !isLoading && !data?.questions?.length ? <EmptyList title="No interview questions" />
                            : isSuccess ?
                                <List className="overflow-y-auto max-h-96">
                                    {data?.questions.map((question: string, index: number) => {
                                        return <ListItem
                                            key={index}
                                            title={question}
                                            className='my-3 text-sm text-gray-600'
                                        />
                                    })}
                                </List>
                                : null
            }
        </>
    )
}

export default InterviewQuestions