import React from 'react'
import ModalComponent from './ModalComponent'
import ModalBody from './ModalBody'
import MoveCandidateForm from '../../../modules/createJob/components/Candidates/MoveCandidates'
import { getImageUrl } from '../../utils/functions/images'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsCandidateModalOpen, setCandidatesModalOpen } from '../../../modules/createJob/slice/candidateSlice'

export const MoveCandidatesModal = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsCandidateModalOpen)
    function closeCandidateModal(): void {
        dispatch(setCandidatesModalOpen(false))
    }

    return (
        <>
            <ModalComponent
                visibleModal={isOpen}
                handleCloseModal={closeCandidateModal}
                content={
                    <ModalBody
                        icon={getImageUrl('move')}
                        title='Move Candidate'
                        subtitle='Move candidate to different job or stage'
                    >
                        <MoveCandidateForm />
                    </ModalBody>
                }
            />
        </>
    )
}
