import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { oneHour, oneMinute } from '../constantData';

dayjs.extend(duration);

const useTimer = (initialTime: number) => {
    const [time, setTime] = useState(initialTime);

    useEffect(() => {
        if (time <= 0) {
            return;
        }

        const intervalId = setInterval(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [time]);

    const formatTime = (seconds: number): string => {
        const timeDuration = dayjs.duration(seconds, 'seconds');
        switch (true) {
            case seconds >= oneHour:
                return timeDuration.format('hh:mm:ss');
            case seconds >= oneMinute:
                return timeDuration.format('mm:ss');
            default:
                return timeDuration.format('mm:ss [sec]');
        }
    };

    return formatTime(time);
};

export default useTimer;
