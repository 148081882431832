import { Tooltip, TooltipProps } from "antd"

export const WithTooltip = ({ visible = true, children, title, placement, width, className }: { visible?: boolean, children: React.ReactNode, title: string, placement: 'top' | 'bottom' | 'right' | 'left', width?: string, className?: string }) => {
    const tooltipProps: TooltipProps = {
        title,
        placement,
        color: "#A5A5BA"
    }
    return visible ?
        <Tooltip {...tooltipProps} overlayInnerStyle={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }} overlayClassName={`text-xs font-normal ${width ? width : 'w-28'} ${className ?? ''}`}>
            {children}
        </Tooltip>
        :
        <>
            {children}
        </>

}