import { ReactNode } from 'react'
import ModalBody from './ModalBody';
import ModalComponent from './ModalComponent';
import warning from '../../assets/images/warning.svg'

export default function DeleteModal({ content, footer, title, isVisibleModal, handleCloseModal }: { title: string, content: string | ReactNode, footer: ReactNode, isVisibleModal: boolean, handleCloseModal: () => void }) {

    return (

        <ModalComponent
            className="my-4"
            isLoadingModal={false}
            visibleModal={isVisibleModal}
            handleCloseModal={handleCloseModal}
            content={

                <ModalBody
                    icon={warning}
                    title={title}
                >
                    <div>
                        <div className="mb-6 text-sm font-normal text-gray-500">
                            {content}
                        </div>
                        {footer}
                    </div>
                </ModalBody>
            }
        />
    )
}
