import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generateAndPrintPDF = (action: 'download' | 'print'): void => {
    const input: HTMLElement | null = document.getElementById('pdf-content');
    if (!input) {
        console.error('Element with ID "pdf-content" not found.');
        return;
    }
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 200;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const padding = 10;

            pdf?.addImage(imgData, 'PNG', padding, padding, imgWidth, imgHeight);

            if (action === 'download') {
                pdf?.save('interview-questions.pdf');
            } else if (action === 'print') {
                return pdf?.output('blob');
            }
        })
        .then((blob) => {
            if (blob && action === 'print') {
                const url = URL?.createObjectURL(blob);
                const printWindow: Window | null = window?.open(url, '_blank');
                if (printWindow !== null) {
                    printWindow.onload = () => {
                        printWindow.print();
                        URL.revokeObjectURL(url);
                    };
                } else {
                    console.error('Failed to open print window.');
                }
            }
        })
        .catch((error) => {
            console.log('Error generating PDF:', error);
        });
};
