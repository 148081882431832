import { ICriteria } from '../../../../shared/utils/interfaces/candidatesListFace'
import Attribute from './Attribute'
import { criteriaMockData } from '../../../../shared/utils/constantData'


export default function Attributes({ applicationCriteriasEvaluation }: { applicationCriteriasEvaluation: ICriteria[] }) {
    return (
        <>
            <div className="flex flex-wrap gap-4 md:flex-nowrap">
                {
                    Array.isArray(applicationCriteriasEvaluation) && applicationCriteriasEvaluation.length ?
                        applicationCriteriasEvaluation?.map((criteria: ICriteria, index: number) => <Attribute criteria={criteria} key={index} />
                        ) : criteriaMockData.map((e, index: number) => <Attribute criteria={e} key={index} />)
                }
            </div>
        </>
    )
}
