import { createSlice } from "@reduxjs/toolkit";

const tabsSlice = createSlice({
    name: 'tabs',
    initialState: { activeTab: '', activeAutomatedActionsTab: '' },
    reducers: {
        setActiveTab: (state, action: { payload: string }) => {
            state.activeTab = action.payload
        },
        setActiveAutomatedActionsTab: (state, action: { payload: string }) => {
            state.activeAutomatedActionsTab = action.payload
        },

    }
})

export const { setActiveTab ,setActiveAutomatedActionsTab} = tabsSlice.actions;

export default tabsSlice.reducer;

export const selectActiveTab = (state: { tabs: { activeTab: string } }) => state.tabs.activeTab
export const selectActiveAutomatedActionsTab = (state: { tabs: { activeAutomatedActionsTab: string } }) => state.tabs.activeAutomatedActionsTab