import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useCookies } from 'react-cookie';
const PublicRoutes = () => {
    const [cookies] = useCookies(['isActive'])
    const { isActive } = cookies
    const location = useLocation()
    return (
        !isActive
            ? <Outlet />
            : <Navigate to="/jobs" state={{ from: location }} replace />
    )
}
export default PublicRoutes