import { useCookies } from 'react-cookie';
import { Layout } from "./layout"

const SharedRoutes = () => {
    const [cookies] = useCookies(['isActive'])

    return (
        <>
            {cookies.isActive
                ?  <><Layout /></>
                : <Layout isPublic />
            }
        </>
    )
}
export default SharedRoutes