import { Button, message } from 'antd'
import Picker, { EmojiClickData, EmojiStyle, SkinTonePickerLocation } from "emoji-picker-react";
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { insertTextarea } from '../../../../../shared/utils/functions/insertTextIntoTextarea';
import './mentionsInput.scss';
import { useAddNewCommentMutation } from '../../../slice/candiddateApiSlice';
import { useParams } from 'react-router-dom';
import { errorMessage } from '../../../../../shared/utils/constantData';
import LazyLoader from '../../../../../shared/components/LazyLoader/load';
import ProfileImage from '../../../../../shared/components/NavBar/profileImage';
import { IMentionMember } from '../../../../../shared/utils/interfaces/AuthInterface';
import { useSelector } from 'react-redux';
import { selectMentions } from '../../../slice/candidateSlice';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../../shared/utils/analytics';
import { getImageUrl } from '../../../../../shared/utils/functions/images';

export default function AddComment({ users }: { users: IMentionMember[] }) {
    const [value, setValue] = useState('');
    const [mentions, setMentions] = useState<IMentionMember[]>([]);
    const [showPicker, setShowPicker] = useState(false);
    const mentionsInputRef = useRef<HTMLTextAreaElement>(null);
    const { applicationId } = useParams();
    const [addComment, { data, isLoading, isSuccess, isError }] = useAddNewCommentMutation();
    const mentionables = useSelector(selectMentions);

    useEffect(() => {
        if (isSuccess) {
            setValue('');
            if (mentionsInputRef.current) mentionsInputRef.current.focus();
        }
        else if (isError) message.error(errorMessage, 5);
    }, [isSuccess, isError, data])

    // Function for maintaining correct mentions markup upon inserting @ sign or emoji
    const handleMentionsOnInsert = (textareaValue: string) => {
        const maintainedValue = textareaValue
            .split(' ')
            .map(word => {
                // If word starts with @ and it exists on users array, add brackets and id so react-mentions can handle
                // mentions correctly upon mention or emoji insertion
                const userExists = users.filter(({ display }) => display === word.slice(1))[0];
                return word.startsWith('@') && userExists ?
                    `@[${userExists?.display}](${userExists?.id})`
                    :
                    word
            })
            .join(' ');
        setValue(maintainedValue);
    }

    const handleChange = (_: any, newValue: string) => {
        setValue(newValue);
        handleDeletedMentions(newValue);
    };

    const handleDeletedMentions = (newValue: string) => {
        const regex = /@\[([^\]]+)\]\(\d+\)/g;
        const newMentions: IMentionMember[] = [];

        let match;
        while ((match = regex.exec(newValue)) !== null) {
            const display = match[1];
            const existingMention = mentions.find(mention => mention.display === display);
            if (existingMention) {
                newMentions.push(existingMention);
            }
        }

        setMentions(newMentions);
    };

    const handleUserSelect = (id: string | number, display: string) => {
        const mentionObject = mentionables.find(mention => mention.id === id)
        const newMentions = [...mentions, {
            id,
            display,
            email: mentionObject ? mentionObject?.email : ''
        }];
        setMentions(newMentions);
    };
    const onEmojiClick = (emojiObject: EmojiClickData) => {
        if (emojiObject.emoji) {
            const newTextareaValue = insertTextarea(mentionsInputRef.current, emojiObject.emoji);
            handleMentionsOnInsert(newTextareaValue);
        }
        setShowPicker(false);
    };

    const handleMentionSomeone = () => {
        const textarea = mentionsInputRef.current;
        if (!textarea) return;

        const position = textarea.selectionStart;
        const prevChar = textarea.value.charAt(position - 1);

        if (prevChar !== "@") {
            const mentionChar = prevChar === ' ' ? '@' : ' @';
            const newTextareaValue = insertTextarea(textarea, mentionChar);
            handleMentionsOnInsert(newTextareaValue);
        } else {
            textarea.setSelectionRange(position, position);
            textarea.focus();
        }
    }

    const handleApply = async () => {
        const commentBody = {
            applicationId: Number(applicationId),
            text: value,
            mentionedUserIds: mentions.map(({ id }) => id)
        }
        await addComment(commentBody);
        logEvent(analytics, 'Add new comment')
    };

    const handleEnterPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.shiftKey === false && value?.trim()) {
            event.preventDefault();
            handleApply();
        }
    }

    return (
        <>
            <div className='flex items-center flex-grow-0 flex-nowrap'>
                <div className='relative flex items-center flex-grow'>
                    <MentionsInput
                        value={value}
                        disabled={isLoading}
                        onChange={handleChange}
                        forceSuggestionsAboveCursor
                        className='mentions-input'
                        placeholder='Add comment..'
                        inputRef={mentionsInputRef}
                        onKeyDown={handleEnterPress}
                    >
                        <Mention
                            className='relative z-10 text-indigo-700'
                            trigger="@"
                            data={users}
                            displayTransform={(_, display) => `@${display}`}
                            markup="@[__display__](__id__)"
                            onAdd={(id, display) => handleUserSelect(id, display)}
                            appendSpaceOnAdd
                            renderSuggestion={({ name, email }: any, _, __, index, focused) => {
                                return <li key={index} className={`p-2 hover:bg-indigo-50 ${focused ? 'bg-indigo-50' : ''}`}>
                                    <div className='flex items-center flex-nowrap'>
                                        <ProfileImage name={name ? name : email} className='me-3.5' />
                                        <div>
                                            <p className='text-sm font-semibold leading-5 text-gray-600'>{name ? name : email}</p>
                                            <p className='text-xs font-normal leading-5 text-gray-400'>{email}</p>
                                        </div>
                                    </div>
                                </li>
                            }}
                        />
                    </MentionsInput>
                    <div className="absolute flex gap-1.5 right-3 min-w-max">
                        <img alt='Mention someone'
                            src={getImageUrl('mentionIcon')}
                            onClick={handleMentionSomeone}
                        />
                        <img alt='Emojis'
                            src={getImageUrl('emojiIcon')}
                            onClick={() => setShowPicker(!showPicker)}
                        />
                    </div>
                    <LazyLoader className={`${isLoading ? 'opacity-1' : 'opacity-0'} min-h-[0.25rem] absolute top-0 z-20 !rounded-bl-none !rounded-br-none`} />
                </div>
                <Button disabled={isLoading || value?.length === 0} className='btn-link' onClick={handleApply}>Post</Button>
                <Picker
                    className='!absolute bottom-3 right-3 z-20'
                    onEmojiClick={onEmojiClick}
                    emojiStyle={EmojiStyle.NATIVE}
                    lazyLoadEmojis
                    skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
                    open={showPicker}
                />
            </div>
        </>
    )
}
