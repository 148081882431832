import { useLocation, Navigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Layout } from "./layout";

const PrivateRoutes = () => {
    const [cookies] = useCookies(['isActive']);
    const { isActive } = cookies;
    const location = useLocation();

    return (
        <>
            {isActive
                ? <><Layout /></>
                : <Navigate to="/login" state={{ from: location }} replace />}
        </>
    );
};

export default PrivateRoutes;
