import { useDispatch, useSelector } from "react-redux";
import CVScore from "../CVScore"
import { Divider } from "antd"
import { selectComparisonCandidates, setAiFiltersArray, setAiSearchFilter, setCandidates, setSearchOpen } from "../../../slice/candidateSlice";
import { ICandidate } from "../../../../../shared/utils/interfaces/candidatesListFace";
import { AISearchPerPage, criteriaMockData, mockCriteriaSummary } from "../../../../../shared/utils/constantData";
import { useEffect } from "react";

function CompareCandidates() {
  const dispatch = useDispatch()
  const comparisonCandidates = useSelector(selectComparisonCandidates);
  const candidatesHaveCriteria = comparisonCandidates?.filter(({ applicationCriteriasEvaluation }) => applicationCriteriasEvaluation?.length)[0]?.applicationCriteriasEvaluation
  const availableCriterias = candidatesHaveCriteria?.length ? candidatesHaveCriteria : criteriaMockData
  const attributes = availableCriterias.map(({ jobCriteria }) => jobCriteria.criteriaName)
  useEffect(() => {
    dispatch(setAiSearchFilter({
      page: 1,
      perPage: AISearchPerPage,
      searchMethodId: 1
    }))
    dispatch(setAiFiltersArray([]))
    dispatch(setCandidates([]))
    dispatch(setSearchOpen(false))
  }, [dispatch])
  return (
    <>
      <Divider dashed className='block mt-2.5 mb-3 border-neutral-500 md:hidden' />
      <div className="h-screen mx-6 overflow-auto scrollbar-hide md:mx-[1.875rem]">
        <div className={`grid grid-cols-${comparisonCandidates?.length} pt-0 md:pt-[1.875rem] gap-5 sticky top-0 bg-light-100`}>
          {comparisonCandidates?.map(({ id, firstName, lastName, cvScore }: ICandidate, index: number) => {
            return <div key={id}>
              <hr className={`h-1 ${index === 0 ? 'bg-rose-500' : index === 1 ? 'bg-indigo-600' : 'bg-green-600'}  w-9 opacity-30`} />
              <h3 className='mt-1.5 font-bold text-gray-600 text-base'>{`${firstName} ${lastName}`}</h3>
              <div className="my-3.5 me-3"><CVScore cvScore={cvScore} /></div>
            </div>
          })}
        </div>
        <Divider dashed className='mb-3.5 mt-0 border-neutral-500' />
        {attributes?.map((name, index) => {
          return <div key={index}>
            <h3 className="mb-1 text-sm font-semibold text-gray-600">{name}</h3>
            <div className={`grid grid-cols-${comparisonCandidates?.length} gap-5`}>
              {comparisonCandidates.map(({ applicationCriteriasEvaluation }) => {
                const criteriaSummary = applicationCriteriasEvaluation?.length ? applicationCriteriasEvaluation.find(({ jobCriteria }) => jobCriteria.criteriaName === name)?.longSummary : criteriaMockData[0].longSummary
                return <>
                  <p className="text-base text-gray-600">{!criteriaSummary ? mockCriteriaSummary : criteriaSummary}</p>
                </>
              })}
            </div>
            <Divider dashed className='my-4 border-neutral-500' />
          </div>
        })}
      </div>
    </>
  )
}

export default CompareCandidates