import Clipboard from '../../../../../shared/assets/images/Clipboard.svg'
import ClipboardDark from '../../../../../shared/assets/images/ClipboardDark.svg'
import MailOpen from '../../../../../shared/assets/images/MailOpen.svg'
import MailOpenDark from '../../../../../shared/assets/images/MailOpenDark.svg'
import graphIcon from '../../../../../shared/assets/images/graphIcon.svg'
import graphIconDark from '../../../../../shared/assets/images/graphIconDark.svg'
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateProfile, setCandidateCv, selectcurrType, setCurrentType } from '../../../slice/candidateSlice';
import { currentTypes } from '../../../../../shared/utils/interfaces/candidatesListFace'
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../../shared/utils/analytics';
import ProfileToggleItem from './ProfileToggleItem'

export default function ProfileToggle({ docViewerRef }: { docViewerRef: any }) {
    const dispatch = useDispatch();
    const candidateProfile = useSelector(selectCandidateProfile);
    const currentType = useSelector(selectcurrType);
    const addCandidateCv = () => {
        docViewerRef?.current?.next()
        dispatch(setCandidateCv(candidateProfile.cv))
        dispatch(setCurrentType(currentTypes.CV))
        logEvent(analytics, 'View candidate cv')
    }
    const addCandidateCoverLetter = () => {
        docViewerRef?.current?.next()
        dispatch(setCandidateCv(candidateProfile.coverLetter));
        dispatch(setCurrentType(currentTypes.coverLetter))
        logEvent(analytics, 'View candidate cover Letter')
    }
    const addCandidateGraph = () => {
        dispatch(setCurrentType(currentTypes.Graph))
    }

    return (

        <div className='flex justify-center gap-3 text-center flex-nowrap md:grid'>
            <ProfileToggleItem
                onToggle={addCandidateGraph}
                active={currentType === currentTypes.Graph}
                icon={graphIcon}
                iconDark={graphIconDark}
                text={'Graph'}
            />
            <ProfileToggleItem
                onToggle={addCandidateCv}
                active={currentType === currentTypes.CV}
                icon={Clipboard}
                iconDark={ClipboardDark}
                text={'CV'}
            />
            {candidateProfile.coverLetter &&
                <ProfileToggleItem
                    onToggle={addCandidateCoverLetter}
                    active={currentType === currentTypes.coverLetter}
                    icon={MailOpen}
                    iconDark={MailOpenDark}
                    text={'Cover Letter'}
                />
            }
        </div>
    )
}
