import { Modal } from 'antd';
import LazyLoader from '../LazyLoader/load';

interface ModalProps {
    isLoadingModal?: boolean,
    visibleModal?: boolean,
    handleCloseModal?: () => void,
    title?: string,
    content?: React.ReactNode,
    className?: string
    width?: number
}
export default function ModalComponent({ isLoadingModal, visibleModal, title, handleCloseModal, content, className, width }: ModalProps) {
    const props = {
        open: visibleModal,
        onCancel: handleCloseModal,
        width,
        title
    }
    return (
        <Modal
            {...props}
            className={`relative overflow-hidden rounded-lg ${className ? className : ''}`}
            centered
            footer={null}
        >
            <>
                {isLoadingModal && <LazyLoader className={`${!isLoadingModal ? 'opacity-0' : ''} absolute top-0 left-0`} />}
                {content}
            </>
        </Modal>
    )
}