
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNYzNaYvEyqX85EdWhICkZ5svXyDJVcIE", // Dev
  // apiKey: "AIzaSyCXt6n1n83hmxibCgWhQ52gs6aqslKlBg4", // ENABLE ON PROD
  authDomain: "people-cq.firebaseapp.com",
  projectId: "people-cq",
  storageBucket: "people-cq.appspot.com",
  messagingSenderId: "464452610679",
  appId: "1:464452610679:web:6f2f82e9101e12e8be3472",
  measurementId: "G-4HEXQDC9BH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

