import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./shared/slices/apiSlice";
import jobReducer from './modules/createJob/slice/jobSlice'
import companyReducer from './modules/createJob/slice/companySlice'
import candidatesReducer from './modules/createJob/slice/candidateSlice'
import authReducer from './modules/authentication/slice/authSlice'
import jobsListReducers from './modules/viewJobs/slice/jobListSlice'
import settingsReducer from './modules/settings/slice/settingsSlice'
import tabsReducer from './shared/components/Tabs/slice/tabsSlice';
import reportsReducer from './modules/reports/slice/reportsSlice';
import pipelinesReducer from './modules/settings/slice/pipelinesSlice';
import templatesReducer from './modules/settings/slice/templatesSlice';
import automatedActionsReducer from './modules/settings/slice/automatedActionsSlice';
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        job: jobReducer,
        auth: authReducer,
        jobsList: jobsListReducers,
        company: companyReducer,
        candidates: candidatesReducer,
        settings: settingsReducer,
        tabs: tabsReducer,
        reports: reportsReducer,
        pipelines: pipelinesReducer,
        templates: templatesReducer,
        automatedActions: automatedActionsReducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
})
export default store;