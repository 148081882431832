import { Button, Empty, Form, message, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { formHasErrors } from '../../../../shared/utils/functions/handleFormChanges';
import { IJobListFilter } from '../../../../shared/utils/interfaces/jobListFaces';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters } from '../../../viewJobs/slice/jobListSlice';
import ChevronDownGray from '../../../../shared/assets/images/ChevronDownGray.svg';
import { useGetJobsListQuery } from '../../../viewJobs/slice/jobListSliceApi';
import { IJob } from '../../../../shared/utils/interfaces/settingsInterface';
import { errorMessage } from '../../../../shared/utils/constantData';
import { useGetStagesByJobIdQuery } from '../../slice/createJobApiSlice';
import { selectCandidateProfile, setCandidatesModalOpen } from '../../slice/candidateSlice';
import { useChangeCandidateJobMutation } from '../../slice/candiddateApiSlice';
import { LoadingOutlined } from '@ant-design/icons';


export default function MoveCandidateForm() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedJobId, setSelectedJobId] = useState<number>();
    const [selectedJobTitle, setSelectedJobTitle] = useState<string>();
    const [selectedStageName, setSelectedStageName] = useState<string>();
    const { shortVersion, page, jobsPerPage }: IJobListFilter = useSelector(selectFilters);
    const candidate = useSelector(selectCandidateProfile);
    const { isLoading: isLoadingJobs, isError: isErrorJobs, data: jobsData } = useGetJobsListQuery({ shortVersion, page, jobsPerPage });
    const { isLoading: isLoadingStages, isError: isErrorStages, data: stagesData } = useGetStagesByJobIdQuery({ jobId: selectedJobId }, { skip: !selectedJobId });
    const [changeCandidateJob, { isLoading: isLoadingMovingCandidate, isError: isErrorMovingCandidate, isSuccess: isSuccessMovingCandidate }] = useChangeCandidateJobMutation();
    const [isMessageShown, setIsMessageShown] = useState(false);

    useEffect(() => {
        if (isErrorJobs) message.error(errorMessage)
    }, [isErrorJobs])

    useEffect(() => {
        if (isErrorStages) message.error(errorMessage)
    }, [isErrorStages])

    useEffect(() => {
        if (isSuccessMovingCandidate && !isMessageShown) {
            message.success(`Candidate moved successfully in ${selectedJobTitle} / ${selectedStageName}`)
            dispatch(setCandidatesModalOpen(false))
            form.resetFields()
            setIsMessageShown(true);
        } else if (isErrorMovingCandidate) message.error(errorMessage)
    }, [isErrorMovingCandidate, isSuccessMovingCandidate, stagesData, dispatch, form, selectedJobTitle, selectedStageName, isMessageShown])

    const handleFormChange = () => {
        setIsDisabled(formHasErrors(form));
    }

    const handleSelectJob = (id: number, option: { title: string }) => {
        setSelectedJobId(id)
        setSelectedJobTitle(option?.title)
    }
    const handleSelectStage = (_id: number, option: { name: string }) => setSelectedStageName(option?.name)

    async function onFinish(values: any) {
        const { id, jobId } = candidate
        await changeCandidateJob({ currentJobId: jobId, applicationId: id, ...values })
    }
    useEffect(() => {
        if (!isSuccessMovingCandidate) {
            setIsMessageShown(false);
        }
    }, [isSuccessMovingCandidate, isMessageShown]);

    return (
        <Form
            form={form}
            className='text-start'
            size='large'
            onFinish={onFinish}
            onFieldsChange={handleFormChange}
        >
            <Form.Item
                name="newJobId"
                rules={[{ required: true, message: 'Job is missing' }]}
            >
                <Select
                    notFoundContent={isLoadingJobs ? <Spin indicator={<LoadingOutlined spin />} size="large" className='flex items-center justify-center m-10 text-gray-600' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    className='w-full text-sm font-normal text-gray-600 placeholder-gray-400 capitalize'
                    placeholder="Select a job"
                    suffixIcon={<img src={ChevronDownGray} alt='Arrow Down' />}
                    onSelect={handleSelectJob}
                    disabled={isErrorJobs}
                >
                    {jobsData?.jobs?.map(({ id, title }: IJob) => {
                        return (<Option key={id} value={id} title={title}>{title}</Option>)
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                name="pipelineStageId"
                rules={[{ required: true, message: 'Stage is missing' }]}
            >
                <Select
                    notFoundContent={isLoadingStages ? <Spin indicator={<LoadingOutlined spin />} size="large" className='flex items-center justify-center m-10 text-gray-600' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    className='w-full text-sm font-normal text-gray-600 placeholder-gray-400 capitalize'
                    placeholder="Select a stage"
                    suffixIcon={<img src={ChevronDownGray} alt='Arrow Down' />}
                    disabled={!selectedJobId || isErrorStages}
                    onSelect={handleSelectStage}
                >
                    {stagesData?.data?.map(({ id, name }: { id: number, name: string }) => {
                        return (<Option key={id} value={id} name={name}>{name}</Option>)
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                className='mb-0'
            >
                <Button
                    loading={isLoadingMovingCandidate}
                    disabled={isDisabled || isLoadingMovingCandidate}
                    className="w-full btn-primary btn-lg"
                    htmlType='submit'
                >
                    Move
                </Button>
            </Form.Item>
        </Form>
    )
}