
import { createSlice } from "@reduxjs/toolkit"
import { CheckboxDropdownItem } from "../../../shared/utils/interfaces/settingsInterface";

const automatedActionsSlice = createSlice({
    name: 'automatedActions',
    initialState: {
        jobsItems: [],
        moveItems: [],
        stagesItems: [],
        emailItems: [],
        senderItems: [],
        isChangingJobsDropdown: false
    },
    reducers: {
        resetCheckedItems: (state) => {
            state.jobsItems = []
            state.moveItems = []
            state.stagesItems = []
            state.emailItems = []
            state.senderItems = []
            state.isChangingJobsDropdown = false
        },
        setJobsItems: (state, action) => {
            state.jobsItems = action.payload;
        },
        setMoveItems: (state, action) => {
            state.moveItems = action.payload;
        },
        setStagesItems: (state, action) => {
            state.stagesItems = action.payload;
        },
        removeJobsFilter: (state) => {
            state.jobsItems = [];
        },
        removeStagesFilter: (state) => {
            state.stagesItems = [];
        },
        clearAllFilters: (state) => {
            state.jobsItems = [];
            state.stagesItems = [];
        },
        setEmailItems: (state, action) => {
            state.emailItems = action.payload;
        },
        setSenderItems: (state, action) => {
            state.senderItems = action.payload;
        },
        setIsChangingJobsDropDown: (state, action) => {
            state.isChangingJobsDropdown = action.payload;
        },
    }
})

export const { setIsChangingJobsDropDown, resetCheckedItems, setJobsItems, setMoveItems, setStagesItems, setEmailItems, setSenderItems, removeJobsFilter, removeStagesFilter, clearAllFilters } = automatedActionsSlice.actions

export default automatedActionsSlice.reducer

export const selectJobsItems = (state: { automatedActions: { jobsItems: CheckboxDropdownItem[] } }) => state.automatedActions.jobsItems;
export const selectMoveItems = (state: { automatedActions: { moveItems: CheckboxDropdownItem[] } }) => state.automatedActions.moveItems;
export const selectStagesItems = (state: { automatedActions: { stagesItems: CheckboxDropdownItem[] } }) => state.automatedActions.stagesItems;
export const selectEmailItems = (state: { automatedActions: { emailItems: CheckboxDropdownItem[] } }) => state.automatedActions.emailItems;
export const selectSenderItems = (state: { automatedActions: { senderItems: CheckboxDropdownItem[] } }) => state.automatedActions.senderItems;
export const selectIsChangingJobsDropdown = (state: { automatedActions: { isChangingJobsDropdown: boolean } }) => state.automatedActions.isChangingJobsDropdown;