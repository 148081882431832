import { lazy } from 'react';
import { Outlet } from "react-router";
import useBulkUploadStatus from '../../../../shared/utils/customHooks/useBulkUploadStatus';
const Nav = lazy(() => import('../../../../shared/components/NavBar/Nav'));

export function Layout({ isPublic }: { isPublic?: boolean }) {

    const { contextHolder } = useBulkUploadStatus();
    return (
        <>
            {contextHolder}
            <Nav isPublic={isPublic} />
            <Outlet />
        </>
    )
};