import { WithTooltip } from '../../../../shared/components/tooltip.tsx/withTootip'
import { getImageUrl } from '../../../../shared/utils/functions/images'
import { ICriteria } from '../../../../shared/utils/interfaces/candidatesListFace'

export default function Attribute({ criteria }: { criteria: ICriteria }) {
    return (
        <>
            <div className='flex-grow border-r-2 md:last:flex-grow-0 last:border-r-0 pe-2'>
                <div className='flex'>
                    <div className='pr-2 font-semibold text-gray-600'>{criteria?.score ?? "NA"}</div>
                    <WithTooltip
                        width='w-36'
                        placement='right'
                        title={criteria.summary}>
                        <img className='max-w-[1rem]' src={getImageUrl('InformationCircle')} alt='informationCircle' />
                    </WithTooltip>
                </div>
                <div className='text-xs text-gray-600'>{criteria?.jobCriteria?.criteriaName ?? "NA"}</div>
            </div>
        </>
    )
}