
import { createSlice } from "@reduxjs/toolkit"
import { ITemplate } from "../../../shared/utils/interfaces/apisInterfaces"

const templatesSlice = createSlice({
    name: 'templates',
    initialState: {
        selectedTemplate: {},
        isAddingNewTemplate: false,
        attributesNames: [],
        attributes: []
    },
    reducers: {
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload
        },
        setIsAddingNewTemplate: (state, action: { payload: boolean }) => {
            state.isAddingNewTemplate = action.payload
        },
        setAttributesNames: (state, action: { payload: [] }) => {
            state.attributesNames = action.payload
        },
        setAttributes: (state, action: { payload: [] }) => {
            state.attributes = action.payload
        }
    },
})

export const { setSelectedTemplate, setIsAddingNewTemplate, setAttributesNames, setAttributes } = templatesSlice.actions

export default templatesSlice.reducer

export const selectSelectedTemplate = (state: { templates: { selectedTemplate: ITemplate } }) => state.templates.selectedTemplate
export const selectIsAddingNewTemplate = (state: { templates: { isAddingNewTemplate: boolean } }) => state.templates.isAddingNewTemplate
export const selectAttributesNames = (state: { templates: { attributesNames: string[] } }) => state.templates.attributesNames
export const selectAttributes = (state: { templates: { attributes: { field: string, isInput: boolean }[] } }) => state.templates.attributes