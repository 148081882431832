import { Button, Divider } from "antd";
import ChevronDownGray from '../../assets/images/ChevronDownGray.svg';
import chevronUpGray from '../../assets/images/chevronUpGray.svg';
import CloseIcon from '../../assets/images/CloseIcon.svg';
import Exclamation from '../../assets/images/Exclamation.svg';
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBatchId } from "../../../modules/createJob/slice/jobSlice";
import { selectIsSendEmailOpen, setHasRunningAutomation } from "../../../modules/createJob/slice/candidateSlice";

interface OperationStackProps {
  title: string | React.ReactNode;
  errorTitle?: string | React.ReactNode;
  count?: string | React.ReactNode;
  operations: React.ReactNode;
  withCloseBtn: boolean
}

export default function OperationStack({ title, errorTitle, count, operations, withCloseBtn }: OperationStackProps) {
  const dispatch = useDispatch()
  const [minimized, setMinimized] = useState(false);
  const batchId = useSelector(selectBatchId);
  const composeEmail = useSelector(selectIsSendEmailOpen);
  const localBatchId = localStorage.getItem('batchId');
  const bulkUpload = useMemo(() => batchId || localBatchId, [batchId, localBatchId]);
  return (
    <div className={`${minimized ? '!w-80' : ''} ${composeEmail ? '!left-6 !right-[unset]' : bulkUpload ? '!bottom-20' : ''} bottom-6 z-[55] overflow-hidden transition-all text-sm fixed bg-white rounded-md shadow-lg right-6 ps-3.5 pe-4 w-[32rem] max-w-[calc(100%-2.5rem)]`}>
      <div className={`${minimized ? 'cursor-pointer' : ''} header text-gray-600 py-3.5 flex flex-nowrap justify-between`} onClick={minimized ? () => setMinimized(false) : () => { }}>
        {minimized && errorTitle ?
          <p className="flex w-full text-red-800 flex-nowrap">
            <img src={Exclamation} alt="Errors" className="me-2" />{errorTitle}
          </p>
          :
          <p>{title} {count}</p>
        }
        <div className="flex">
          <Button className="p-0 btn-link h-fit" onClick={() => setMinimized(!minimized)}>
            <img src={ChevronDownGray} className={`${minimized ? 'hidden' : ''} w-5 h-5`} alt="Minimize" />
            <img src={chevronUpGray} className={`${minimized ? '' : 'hidden'} w-5 h-5`} alt="Maximize" />
          </Button>
          {
            withCloseBtn ?
              <Button className="p-0 ml-2 btn-link h-fit" onClick={() => dispatch(setHasRunningAutomation(false))}>
                <img className="inline w-5 h-5 cursor-pointer" src={CloseIcon} alt="" />
              </Button> : <></>
          }
        </div>
      </div>
      <Divider className="m-0" />
      <div className={`${minimized ? '!h-0' : ''} overflow-auto max-h-80 min-h-fit transition-all pe-3 body`}>
        {operations}
      </div>
    </div>
  )
}
