import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../modules/authentication/slice/authSlice';
import { selectActiveTab } from '../../components/Tabs/slice/tabsSlice';
import { useLocation, useNavigate } from 'react-router-dom';

export const useDemoMode = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const settingActiveTab = useSelector(selectActiveTab)
    const isDemoMode = useSelector(selectCurrentUser)?.isDemoActive
    const myPage = location.pathname.includes('company');
    const reports = location.pathname.includes('reports');

    const toggleMode = () => {
        if (!settingActiveTab && !myPage && !reports) navigate('/jobs')
        navigate(0)
    };

    return {
        isDemoMode,
        toggleMode,
    };
};