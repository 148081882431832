import { apiSlice } from "../../../shared/slices/apiSlice";
import { IAiSearchBody, IChangeCandidateJobBody, IChangeCandidateStage, IInvitationData, ISearchParams, IUpdateUserRoleBody } from "../../../shared/utils/interfaces/candidatesListFace";
import { tagTypes } from "../../../shared/utils/constantData";
import { IAddComment } from "../../../shared/utils/interfaces/commentsFaces";
import { IEmailBody } from "../../../shared/utils/interfaces/apisInterfaces";
const { job, candidates, candidate, members, comments, aiSearch, templates, search } = tagTypes
export const candidateApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        getCandidatesByJobId: builder.query({
            query: (args: any) => {
                const { jobId, page, perPage, sortBy, hiringStageId } = args;
                return {
                    url: `/applications/job/${jobId}`,
                    params: { page, perPage, sortBy, hiringStageId },
                };
            },
            providesTags: [candidates]
        }),
        getCandidateById: builder.query({
            query: (candidateId: number) => ({
                url: `/applications/${candidateId}`
            }),
            providesTags: [candidate]
        }),
        changeCandidateStage: builder.mutation({
            query: (initialActionsData: IChangeCandidateStage) => ({
                url: '/applications',
                method: 'PATCH',
                body: initialActionsData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : localStorage.getItem("activeAiFilter") ? [job, aiSearch] : [job, candidates, candidate]
        }),
        updateHiringPipeline: builder.mutation({
            query: (hiringStageData: {
                jobId: number,
                hiringStagesOrder: number[]
            }) => ({
                url: "/jobs/pipeline",
                method: "PATCH",
                body: hiringStageData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [job, candidates]
        }),
        getMembers: builder.query({
            query: (jobId: number) => `/users?jobId=${jobId}`,
            providesTags: [members]
        }),
        getMembersRoles: builder.query({
            query: () => `/users/roles`,
        }),
        inviteNewUser: builder.mutation({
            query: (invitationData: IInvitationData) => ({
                url: "/users/invitation",
                method: "POST",
                body: invitationData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [members]
        }),
        updateUserRole: builder.mutation({
            query: (updateRoleData: IUpdateUserRoleBody) => ({
                url: '/users/roles',
                method: "PATCH",
                body: updateRoleData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [members]
        }),
        removeUserRole: builder.mutation({
            query: (updateRoleData: IUpdateUserRoleBody) => ({
                url: '/users/roles',
                method: "DELETE",
                body: updateRoleData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [members]
        }),
        checkMemberInvitation: builder.query({
            query: (args: any) => {
                const { invitationId } = args;
                return {
                    url: `/users/invitation/check`,
                    params: { invitationId },
                };
            },
        }),
        getComments: builder.query({
            query: (candidateId: number) => ({
                url: `/applications/${candidateId}/comment`
            }),
            providesTags: [comments]
        })
        ,
        addNewComment: builder.mutation({
            query: (commentData: IAddComment) => ({
                url: "/applications/comment",
                method: "POST",
                body: commentData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [comments]
        }),
        getInterviewsQuestionsByCandidateId: builder.query({
            query: (candidateId: number) => ({
                url: `/applications/${candidateId}/questions`
            }),
        }),
        getCandidatesByIds: builder.query({
            query: (candidatesIds: string) => ({
                url: `/applications?ids=${candidatesIds}`
            })
        }),
        getCandidatesByAiSearch: builder.query({
            query: (args: IAiSearchBody) => {
                const { jobId, query, page, perPage, searchMethodId, hiringStageId } = args;
                return {
                    url: `/applications/aiSearch`,
                    params: { query, page, perPage, searchMethodId, hiringStageId, jobId },
                };
            },
            providesTags: [aiSearch]
        }),
        getTemplatesByApplicationID: builder.query({
            query: (applicationId: number) => ({
                url: `companies/emails/templates`,
                params: { applicationId }
            }),
            providesTags: [templates]
        }),
        sendEmail: builder.mutation({
            query: (emailBody: IEmailBody) => ({
                url: "/users/emails",
                method: "POST",
                body: emailBody
            })
        }),
        cancelEmail: builder.mutation({
            query: (itemIID: number) => ({
                url: `/automations/cancelInstance/${itemIID}`,
                method: "PATCH",
            })
        }),
        deleteCandidate: builder.mutation({
            query: ({ id }: { id: number }) => ({
                url: '/applications',
                method: "DELETE",
                params: { id }
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [candidates, job, search]
        }),
        getJobStages: builder.query({
            query: (jobIds?: number[]) => {
                const jobIdsQuery = jobIds && jobIds.length > 0 ? `jobIds=${jobIds.join(',')}` : '';
                return `/companies/stages${jobIdsQuery ? `?${jobIdsQuery}` : ''}`;
            },
        }),
        getAllCandidates: builder.query({
            query: (args?: ISearchParams) => {
                const { jobsIds, searchQuery, page, perPage, sortBy, pipelineHiringStageIds } = args || {};
                const params: Record<string, any> = {};
                if (searchQuery) params.searchQuery = searchQuery;
                if (page) params.page = page;
                if (perPage) params.perPage = perPage;
                if (sortBy) params.sortBy = sortBy;
                if (pipelineHiringStageIds) params.pipelineHiringStageIds = pipelineHiringStageIds;
                if (jobsIds) params.jobsIds = jobsIds;

                return {
                    url: `/applications/search`,
                    params: Object.keys(params).length ? params : undefined,
                };
            },
            providesTags: [search]
        }),
        changeCandidateJob: builder.mutation({
            query: (changeData: IChangeCandidateJobBody) => ({
                url: '/applications/changeJob',
                method: 'POST',
                body: changeData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [candidates, job, search]
        })
    })
})
export const { useChangeCandidateJobMutation, useDeleteCandidateMutation, useGetCandidatesByJobIdQuery, useGetCandidateByIdQuery, useChangeCandidateStageMutation, useUpdateHiringPipelineMutation, useGetJobStagesQuery, useLazyGetAllCandidatesQuery, useGetAllCandidatesQuery,
    useGetMembersQuery, useGetMembersRolesQuery, useInviteNewUserMutation, useCheckMemberInvitationQuery, useGetCommentsQuery, useAddNewCommentMutation, useUpdateUserRoleMutation, useRemoveUserRoleMutation, useGetInterviewsQuestionsByCandidateIdQuery, useGetCandidatesByIdsQuery, useGetCandidatesByAiSearchQuery, useGetTemplatesByApplicationIDQuery, useSendEmailMutation, useCancelEmailMutation } = candidateApiSlice
