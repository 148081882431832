import { IHeaderOverride } from "@cyntler/react-doc-viewer";
import { useSelector } from "react-redux";
import { selectCandidateCv } from "../../../modules/createJob/slice/candidateSlice";
import { getImageUrl } from '../../utils/functions/images';

export const Toolbar: IHeaderOverride = (state) => {
    const candidateCv = useSelector(selectCandidateCv)
    const handleDownload = () => {
        fetch(candidateCv).then(response => response.blob()).then((blob) => {
            const url = URL?.createObjectURL(blob)
            const fileName = candidateCv.split('/').pop() as string
            const aTag = document.createElement("a")
            aTag.href = url
            aTag.setAttribute("download", fileName)
            document.body.appendChild(aTag)
            aTag.click()
            aTag.remove()
        })
    }
    const handlePrint = () => {
        const printWindow = window.open(state.currentDocument?.uri, state.currentDocument?.uri.split('.').pop() === 'docx' ? '_self' : '_blank') as Window;
        printWindow.addEventListener('load', () => {
            printWindow.print();
        });
    }
    return (
        <div className='sticky top-0 left-0 z-50 flex justify-end w-full pb-5 pr-6 pt-7 bg-light-100'>
            <div className='flex justify-end gap-x-4'>
                <img src={getImageUrl('download')} alt="download" className='mx-3 cursor-pointer' onClick={handleDownload} />
                <img className='cursor-pointer' onClick={handlePrint} src={getImageUrl('Printer')} alt='print' />
            </div>
        </div>
    )
};