import React from 'react'
import { List } from 'antd'
interface ListItemProps {
    title?: string | React.ReactNode
    avatar?: React.ReactNode,
    description?: string | React.ReactNode,
    actions?: React.ReactNode[],
    className?: string,
}
function ListItem({ title, avatar, description, actions, className }: ListItemProps) {
    return (
            <List.Item
                actions={actions?.map((action) => action)}
            >
                <List.Item.Meta
                    className={`font-semibold !items-center ${className ?? ''} ${description ? 'mb-3.5' : ''}`}
                    avatar={avatar ?? false}
                    title={<span>{title}</span>}
                    description={description ?? false}
                />
        </List.Item>
    )
}

export default ListItem