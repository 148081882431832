import { Button } from 'antd';
import { getImageUrl } from '../../utils/functions/images';

type WarningProps = {
  icon?: React.ReactNode,
  title: string,
  message?: string,
  btnText?: string,
  onRefresh?: any,
  className?: string
}


export const Warning = ({
  icon = <img alt="Warning!" src={getImageUrl('warningGray')} />,
  title,
  message,
  btnText = "Refresh",
  onRefresh,
  className }: WarningProps) => {
  const defaultOnClick = () => window.location.reload();
  const navigateToHome = () => window.location.replace('/');
  return (
    <div className={`api-error max-w-[280px] mx-auto flex flex-wrap justify-center my-6 gap-6 text-center ${className ?? ''}`}>
      <div>{icon}</div>
      <div className='w-full'>
        <h4 className='mb-1 text-lg font-medium text-gray-900'>{title}</h4>
        <p className='text-sm font-normal text-gray-500'>{message}</p>
      </div>
      <div>
        <Button
          type='primary'
          className='text-sm font-medium text-indigo-700 bg-indigo-100'
          onClick={onRefresh || defaultOnClick}
        >{btnText}</Button>
        <Button
          type='primary'
          className='ml-2 text-sm font-medium text-indigo-700 bg-indigo-100'
          onClick={navigateToHome}
        >Home</Button>
      </div>
    </div>

  )
};