const ProfileImage = ({ name, className }: { name: string, className?: string }) => {
  const nameParts = name.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0].toUpperCase() : "";
  const lastNameInitial = nameParts[1] ? nameParts[1][0].toUpperCase() : "";
  return (
    <span className={`${className ?? ''} flex justify-center items-center min-w-[2rem] w-8 h-8 bg-gray-500 text-white text-sm rounded-full font-normal`}>
      {firstNameInitial}
      {lastNameInitial}
    </span>
  );
};
export default ProfileImage;