export default function FeaturesList({ title, list }: { title: string, list: string[] }) {
    return (
        <div>
            <p className='text-base font-semibold text-gray-600'>{title}</p>
            <ul className='pl-5 text-base text-gray-600 list-disc'>
                {Array.isArray(list) ? list.map((item, index) => <li key={index}>{item}</li>) : ''}
            </ul>
        </div>
    )
}
