import { Button, Divider, Form, Input, message, Select, Spin } from "antd";
import caretDownGray from '../../../../../shared/assets/images/caretDownGray.svg';
import Check from '../../../../../shared/assets/images/Check.svg'
import minimizeIcon from '../../../../../shared/assets/images/minimizeIcon.svg'
import maximizeIcon from '../../../../../shared/assets/images/maximizeIcon.svg'
import closeComposeEmail from '../../../../../shared/assets/images/closeComposeEmail.svg'
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { useEditor, EditorContent } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";
import { selectCandidateProfile, selectIsSendEmailOpen, setIsSendEmailOpen } from "../../../slice/candidateSlice";
import TextEditorToolbar from "../../../../../shared/components/TextEditor/TextEditorToolbar";
import { useGetTemplatesByApplicationIDQuery, useSendEmailMutation } from "../../../slice/candiddateApiSlice";
import { useParams } from "react-router-dom";
import { ITemplate } from "../../../../../shared/utils/interfaces/apisInterfaces";
import { apiStatuses, errorMessage } from "../../../../../shared/utils/constantData";
import { selectCurrentUser } from "../../../../authentication/slice/authSlice";
import { getImageUrl } from "../../../../../shared/utils/functions/images";
import { IConnectEmail } from "../../../../../shared/utils/interfaces/AuthInterface";
import attachmentIcon from '../../../../../shared/assets/images/attachmentIcon.svg';
import Highlight from "@tiptap/extension-highlight";
import { regexPatterns } from "../../../../../shared/utils/regexPatterns";
import { useDemoMode } from "../../../../../shared/utils/customHooks/useDemoHook";
import { ReactComponent as LabIcon } from '../../../../../shared/assets/images/lab.svg'
import RequestError from "../../../../../shared/components/Errors/RequestError";

const { Option } = Select;

export default function ComposeEmail() {
    const dispatch = useDispatch();
    const [minimized, setMinimized] = useState(false);
    const [ccOn, setCcOn] = useState(false);
    const [form] = useForm();
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const candidateEmail = useSelector(selectCandidateProfile)?.email
    const connectedEmail = useSelector(selectCurrentUser)?.connectedEmail
    const isSendEmailOpen = useSelector(selectIsSendEmailOpen);
    const extensions: any[] = [
        StarterKit,
        Highlight,
        Highlight.configure({ multicolor: true }),
        Link.configure({
            openOnClick: false,
            autolink: true,
            linkOnPaste: true,
            HTMLAttributes: {
                class: 'underline',
                target: '_blank'
            }
        })
    ];
    const editor = useEditor({
        extensions,
        content: body,
        onUpdate: ({ editor }: { editor: any }) => setBody(editor?.getHTML()),
        editorProps: {
            attributes: {
                class: 'min-h-[7.5rem] max-h-[14rem] p-0 m-0 overflow-auto focus:outline-none',
            },
        },
    });
    const subjectEditor = useEditor({
        extensions,
        content: subject,
        onUpdate: ({ editor }: { editor: any }) => setSubject(editor?.getHTML()),
        editorProps: {
            attributes: {
                class: 'p-0 m-0 overflow-x-auto overflow-hidden scrollbar-hide [&_*]:whitespace-nowrap [&_*]:text-base [&_*]:font-normal [&_*]:text-gray-600 focus:outline-none',
            },
        },
    });
    const { applicationId } = useParams();
    const { data, isError, isSuccess, isLoading } = useGetTemplatesByApplicationIDQuery(applicationId, { skip: !isSendEmailOpen });
    const [sendEmail, { isSuccess: emailSuccess, isLoading: EmailLoading, isError: isEmailError, error: emailError }] = useSendEmailMutation();
    const [alltempelates, setAllTempelates] = useState<ITemplate[]>([]);
    const { isDemoMode } = useDemoMode()
    const customLabIcon = <LabIcon className="mr-2" />

    useEffect(() => {
        if (isSuccess) {
            const { companyEmailTemplates, defaultEmailTemplates } = data?.data
            const combinedTemplates: ITemplate[] = [...companyEmailTemplates, ...defaultEmailTemplates]
            setAllTempelates(combinedTemplates)
        } else if (isError) {
            message.error(errorMessage)
        }
    }, [data, isError, isSuccess])

    useEffect(() => {
        if (emailSuccess) {
            message.success("Email successfully sent");
            dispatch(setIsSendEmailOpen(false));
            resetFields()
            setIsSubmitting(false)
        } else if (isEmailError) {
            setIsSubmitting(false)
            emailError?.data?.statusCode === apiStatuses.badRequest ? message.error("Invalid Email Address") : message.error(errorMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailSuccess, isEmailError, emailError, dispatch]);

    useEffect(() => {
        if ((body === '<p></p>' || !body) && (subject === '<p></p>' || !subject)) setIsDisabled(true)
        else setIsDisabled(false)
    }, [body, subject])

    const onTemplateChange = (value: number) => {
        const selectedTemplate = alltempelates?.find((template: ITemplate) => template?.id === value);
        if (selectedTemplate) {
            const BodyElement = selectedTemplate?.body?.replaceAll('<_', '<mark>').replaceAll('_>', '</mark>')
            const SubjectElement = selectedTemplate?.subject?.replaceAll('<_', '<mark>').replaceAll('_>', '</mark>')
            editor?.commands?.setContent(BodyElement);
            subjectEditor?.commands?.setContent(SubjectElement);
            setBody(BodyElement);
            setSubject(SubjectElement);
        }
    };

    const handleClose = () => {
        setMinimized(false);
        dispatch(setIsSendEmailOpen(false))
        resetFields()
        setIsSubmitting(false)
    }

    const resetFields = () => {
        editor?.commands?.setContent('');
        subjectEditor?.commands?.setContent('');
        setBody('');
        setSubject('');
    }

    const onFinish = async (values: any) => {
        setIsSubmitting(true)
        const { template, ...otherValues } = values;
        const emailSubject = subject?.replaceAll('<mark>', '').replaceAll('</mark>', '');
        const emailBody = body?.replaceAll('<mark>', '').replaceAll('</mark>', '');
        const finalBody = { applicationId: Number(applicationId), ...otherValues, subject: emailSubject, body: emailBody };
        if ((emailBody !== '<p></p>' && emailBody) && (emailSubject !== '<p></p>' && emailSubject)) {
            if (isDemoMode) {
                message.warning({
                    content: <span className="flex text-yellow-800">Demo Mode email successfully sent</span>,
                    icon: customLabIcon,
                })
                dispatch(setIsSendEmailOpen(false))
                resetFields()
                setIsSubmitting(false)
            } else await sendEmail(finalBody);
        }
    }

    const divider = <Divider className="m-0 border-[##DCDCE4]" />;
    return (
        <div className={`${minimized ? 'pt-3.5 pb-0 px-5 max-w-[21.125rem]' : 'p-5 max-w-[32rem]'} transition-all fixed bottom-0 bg-white shadow-xl mx-8 right-0 z-[51] rounded-t-2xl w-[calc(100%-4rem)]`}>
            <div className={`${minimized ? 'pb-3.5' : 'pb-5'} transition-all flex items-center justify-between flex-nowrap`} onClick={minimized ? () => setMinimized(false) : () => { }}>
                <h4 className="text-lg font-medium leading-6 text-gray-900">Send Email</h4>
                <div className="gap-x-2.5 flex flex-nowrap grow justify-end">
                    <Button className="p-0 h-fit btn-link" onClick={() => setMinimized(!minimized)}>
                        <img className={`${minimized ? 'hidden' : ''}`} src={minimizeIcon} alt="Minimize" />
                        <img className={`${minimized ? '' : 'hidden'}`} src={maximizeIcon} alt="Maximize" />
                    </Button>
                    <Button className="p-0 h-fit btn-link" onClick={handleClose}><img src={closeComposeEmail} alt="Cancel" /></Button>
                </div>
            </div>
            {divider}
            <div className={`${minimized ? 'h-0' : 'h-[27rem] max-h-[33rem]'} scrollbar-hide overflow-auto transition-all`}>
                <ComposeRow label="From" isConnectedEmail={connectedEmail} />
                {divider}
                <div className="flex items-center justify-between flex-nowrap">
                    <ComposeRow label="To" text={candidateEmail} />
                    {!ccOn &&
                        <Button onClick={() => setCcOn(true)} className="p-0 btn-link me-2"><span className="text-base font-semibold text-gray-600 ">Cc</span></Button>
                    }
                </div>
                {divider}
                <Form
                    onFinish={onFinish}
                    name="email-compose"
                    form={form}
                >
                    {ccOn ?
                        <>
                            <Form.Item
                                className="[&>*]:w-full m-0 py-3 [&_label]:!h-full flex items-center [&_label]:after:!content-none [&_label]:before:!content-none"
                                label={<p className="w-20 text-base font-semibold text-gray-600 text-start">Cc</p>}
                                name="cc"
                                rules={[{ pattern: regexPatterns.emailsWithComma, message: 'Enter valid emails separated by comma' }]}
                            >
                                <Input className="px-0" type="text" bordered={false} />
                            </Form.Item>
                            {divider}
                        </>
                        : null
                    }
                    <Form.Item
                        className="[&_.ant-form-item-row]:w-full m-0 py-3 [&_label]:!h-full flex items-center [&_label]:after:!content-none [&_label]:before:!content-none [&_.ant-form-item-explain]:absolute [&_.ant-form-item-explain]:top-0 [&_.ant-form-item-explain]:pointer-events-none"
                        label={<p className="w-20 text-base font-semibold text-gray-600 text-start">Subject</p>}
                        rules={[{ required: true, message: 'Subject is missing' }]}
                    >
                        <EditorContent editor={subjectEditor} />
                    </Form.Item>
                    {(subject === '<p></p>' || !subject) && isSubmitting && <RequestError className='!mt-0'>Subject is missing</RequestError>}
                    {divider}
                    <Form.Item
                        className="m-0 py-3 [&_label]:!h-full flex items-center [&_label]:after:!content-none [&_label]:before:!content-none"
                        label={<p className="w-20 text-base font-semibold text-gray-600 text-start">Template</p>}
                        name="template"

                    >
                        <Select
                            popupClassName='p-2 rounded-xl [&_.ant-select-item]:py-2 [&_.ant-select-item]:px-4 [&_.ant-select-item-option-active:not(.ant-select-item-option-disabled)]:bg-indigo-50'
                            // disabled={}
                            onChange={onTemplateChange}
                            loading={isLoading}
                            placeholder='Select'
                            className="text-base bg-transparent border-none round"
                            suffixIcon={<img src={caretDownGray} alt='Caret Down' />}
                        >
                            {isLoading ? (
                                <Option key="loading" value="loading">
                                    <Spin size="small" />
                                </Option>
                            ) : (alltempelates?.map((tempelate, index) => (
                                <Option key={index} value={tempelate?.id}>{tempelate?.title}</Option>
                            )))
                            }
                        </Select>
                    </Form.Item>
                    {divider}
                    <Form.Item
                        className="py-3 m-0"
                    >
                        <EditorContent editor={editor} />
                    </Form.Item>
                    {(body === '<p></p>' || !body) && isSubmitting && <RequestError className='!mt-0'>Body is missing</RequestError>}
                </Form>
            </div>
            {divider}
            <div className={`${minimized ? 'h-0 pt-0 overflow-hidden' : 'pt-3'} flex justify-between`}>
                <div className="flex items-center flex-nowrap">
                    <Button
                        form='email-compose'
                        htmlType="submit"
                        disabled={!connectedEmail || EmailLoading || isDisabled}
                        loading={EmailLoading}
                        className={`btn-primary btn-md ${!connectedEmail ? 'cursor-not-allowed' : ''}`}
                    >Send
                        <img src={Check} className="inline btn-icon ms-2" alt="Send" />
                    </Button>
                    <TextEditorToolbar editor={editor} />
                    <Button onClick={() => { }} className={`btn-link h-fit p-0`}>
                        <img src={attachmentIcon} alt="Attach" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

const ComposeRow = ({ label, text, isConnectedEmail }: { label: string, text?: string, isConnectedEmail?: IConnectEmail | null }) => {
    return <div className="flex py-3 flex-nowrap">
        <p className="w-20 my-auto text-base font-semibold text-gray-600 h-fit">{label}</p>
        {label === 'From' && !isConnectedEmail ?
            <div className='flex w-full'>
                <img className='ml-3 mr-1.5' src={getImageUrl('Exclamation')} alt='error' />
                <span className="text-base leading-6 text-red-800">No email integrated.<a className="pl-2 text-base font-medium leading-6 text-red-800 underline underline-offset-4" href="/settings/email-integration">Integrate It Now</a></span>
            </div>
            : label === 'From' && isConnectedEmail ? < p className="px-2 py-1 text-base font-normal text-gray-600 rounded-md w-fit bg-indigo-50">{isConnectedEmail?.email}</p>
                : < p className="px-2 py-1 text-base font-normal text-gray-600 rounded-md w-fit bg-indigo-50">{text}</p>
        }

    </div>
}