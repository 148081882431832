import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAutomatedNotification } from "../interfaces/candidatesListFace"
import { automatedActionStatus } from "../interfaces/settingsInterface"
import { apiStatuses, baseUrl, errorMessage } from "../constantData"
import { selectAutomatedActionsNotifications, selectHasRunningAutomation, setAutomatedActionNotifications } from "../../../modules/createJob/slice/candidateSlice"
import { message } from "antd"


const useAutomatedActionNotification = () => {
    const dispatch = useDispatch()
    const [errorsCount, setErrorsCount] = useState(0)
    const [successCount, setSuccessCount] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const { done, failed } = automatedActionStatus
    const notifications = useSelector(selectAutomatedActionsNotifications)
    const hasRunningAutomation = useSelector(selectHasRunningAutomation);

    useEffect(() => {
        if (!hasRunningAutomation) {
            return;
        }
        let eventSource: EventSource;
        try {
            eventSource = new EventSource(`${baseUrl}automations/automationInstanceSSE`, { withCredentials: true });

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event?.data) as { isComplete: boolean, status: apiStatuses, automationInstances: IAutomatedNotification[], error?: string };
                    if (data?.status === apiStatuses.success) {
                        setIsLoading(false)
                        const newErrorsCount = data.automationInstances.filter(({ status }: IAutomatedNotification) => status === failed).length;
                        const newSuccessCount = data.automationInstances.filter(({ status }: IAutomatedNotification) => status === done).length;

                        setErrorsCount(newErrorsCount);
                        setSuccessCount(newSuccessCount);
                        setIsComplete(data.isComplete)
                        dispatch(setAutomatedActionNotifications(data.automationInstances));

                        if (data.isComplete) {
                            eventSource.close();
                        }
                    } else {
                        setIsLoading(false)
                        message.error(data.status === apiStatuses.connectionTimeOut ? data.error : errorMessage)
                    }
                } catch (e) {
                    eventSource.close();
                    message.error('Something failed with Sending emails')
                }
            };

            eventSource.onerror = (error) => {
                message.error('Something failed with Sending emails')
                eventSource.close();
            };
        } catch (e) {
            message.error('Something failed with Sending emails')

        }

        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
        // eslint-disable-next-line
    }, []);

    return { notifications, successCount, errorsCount, isComplete, isLoading }
}
export default useAutomatedActionNotification; 