import { Skeleton } from 'antd'

export default function SkeletonList({ hasLogoSkelton, isLoading }: { hasLogoSkelton: boolean, isLoading: boolean }) {
    return (
        <div className='max-h-96'>
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
            <Skeleton paragraph={{ rows: hasLogoSkelton ? 2 : 1, width: hasLogoSkelton ? 420 : 483 }} className='mb-4' avatar={hasLogoSkelton} title={false} loading={isLoading} active />
        </div>
    )
}