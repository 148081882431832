import classNames from "classnames";
import boldIcon from '../../../shared/assets/images/boldIcon.svg'
import italicIcon from '../../../shared/assets/images/italicIcon.svg'
import orderedListIcon from '../../../shared/assets/images/orderedListIcon.svg'
import bulletsListIcon from '../../../shared/assets/images/bulletsListIcon.svg'
import linkIcon from '../../../shared/assets/images/linkIcon.svg'
import { useCallback } from "react";
import { Button } from "antd";

export default function TextEditorToolbar({ editor, iconsSize }: { editor: any, iconsSize?: string }) {
    const setLink = useCallback(() => {
        const previousUrl = editor?.getAttributes('link').href
        let url;
        //toggle link
        if (previousUrl) {
            editor?.chain().focus().unsetLink().run()
            url = null
        } else url = window.prompt('Enter link URL', previousUrl)
        // cancelled
        if (url === null) {
            return
        }
        // empty
        if (url === '') {
            editor?.chain().focus().extendMarkRange('link').unsetLink().run()
            return
        }
        // update link
        editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    }, [editor])

    return (
        <div className="relative flex flex-nowrap items-center gap-x-2.5 min-w-max mx-2.5">
            <Button
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={`btn-link h-fit p-0 ${classNames(
                    editor && editor?.isActive("bold") ? "!bg-gray-300 rounded" : "",
                )}`}
            >
                <img className={`${iconsSize ? iconsSize : ''}`} src={boldIcon} alt="Bold" />
            </Button>
            <Button
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={`btn-link h-fit p-0 ${classNames(
                    editor && editor?.isActive("italic") ? "!bg-gray-300 rounded" : "",
                )}`}
            >
                <img className={`${iconsSize ? iconsSize : ''}`} src={italicIcon} alt="Italic" />
            </Button>
            <Button
                onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                className={`btn-link h-fit p-0 ${classNames(
                    editor && editor?.isActive("orderedList") ? "!bg-gray-300 rounded" : "",
                )}`}
            >
                <img className={`${iconsSize ? iconsSize : ''}`} src={orderedListIcon} alt="Ordered List" />
            </Button>
            <Button
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={`btn-link h-fit p-0 ${classNames(
                    editor && editor?.isActive("bulletList") ? "!bg-gray-300 rounded" : "",
                )}`}
            >
                <img className={`${iconsSize ? iconsSize : ''}`} src={bulletsListIcon} alt="Bullet Points List" />
            </Button>
            <Button onClick={setLink} className={`btn-link h-fit p-0 ${editor && editor?.isActive('link') ? '!bg-gray-300 rounded' : ''}`}>
                <img className={`${iconsSize ? iconsSize : ''}`} src={linkIcon} alt="Link" />
            </Button>
        </div>
    )
}
