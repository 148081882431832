import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { selectComparisonCandidates } from '../../../slice/candidateSlice';
import { criteriaMockData } from '../../../../../shared/utils/constantData';

export default function Chart() {
  const ComparisonCandidates = useSelector(selectComparisonCandidates);

  const hasAttributes = () => {
    let candidatesWithAttributes = ComparisonCandidates.filter((candidate) => { return candidate?.applicationCriteriasEvaluation?.length > 0 })
    let Attributes = criteriaMockData.map((mcriteria) => {
      return { name: mcriteria?.jobCriteria?.criteriaName, max: 10, color: 'black' }
    })
    if (candidatesWithAttributes.length > 0) {
      Attributes = candidatesWithAttributes[0].applicationCriteriasEvaluation.map((criteria) => {
        return { name: criteria?.jobCriteria?.criteriaName, max: 10, color: 'black' }
      })
    }
    return Attributes
  }

  const option = {
    color: ["#F43F5EA6", "#4F46E5A6", "#057a55A6"],
    legend: {
      data: Array.isArray(ComparisonCandidates) && ComparisonCandidates?.length > 0 ?
        [...ComparisonCandidates.map(({ firstName, id }) => { return firstName + id })] : [0],
      show: false
    },
    radar: {
      indicator: hasAttributes(),
      axisName: {
        backgroundColor: "#FFFFFF",
        lineHeight: 26,
        borderRadius: 6,
        fontSize: 10,
        fontWeight: 400,
        padding: [0, 8],
        color: "#4B5563"
      }
    },
    series: [
      {
        type: 'radar',
        areaStyle: {
          opacity: 0.30
        },
        lineStyle: {
          opacity: 0.10
        },
        data:
          Array.isArray(ComparisonCandidates) && ComparisonCandidates?.length > 0 ?
            ComparisonCandidates.map((candidate) => {
              const value = candidate?.applicationCriteriasEvaluation?.map((criteria) => criteria?.score)
              return {
                value,
                name: candidate?.firstName + candidate?.id,
                symbol: 'circle',
                symbolSize: 7,
              };
            }) : [0]
      },
    ]
  };

  return (

    <div className="mt-10">
      <ReactECharts option={option} className='w-full !h-[28rem]' />
    </div>
  )
}
