import { Company } from '../../../shared/utils/interfaces/companyInterface';
import { createSlice } from "@reduxjs/toolkit"
import { IJobListFilter } from '../../../shared/utils/interfaces/jobListFaces';
import { ICName } from '../../../shared/utils/interfaces/apisInterfaces';
import { jobsPerPage } from '../../../shared/utils/constantData';
const companySlice = createSlice({
    name: 'Company',
    initialState: {
        privateCompany: {},
        publishJobs: [],
        pagination: {
            page: 1,
            jobsPerPage: jobsPerPage,
        },
        customUrlCurrentStep: 0,
        cnameData: []
    },
    reducers: {
        setCompanyData: (state, action) => {
            state.privateCompany = action.payload
        },
        setPublishJobs: (state, action) => {
            state.publishJobs = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setCustomUrlCurrentStep: (state, action) => {
            state.customUrlCurrentStep = action.payload
        },
        setCnameData: (state, action) => {
            state.cnameData = action.payload
        }
    },
})

export const { setCompanyData, setPublishJobs, setPagination, setCustomUrlCurrentStep, setCnameData } = companySlice.actions

export default companySlice.reducer

export const selectCompanyData = (state: { company: { privateCompany: Company } }) => state.company.privateCompany
export const selectPublishJobs = (state: { company: { publishJobs: [] } }) => state.company.publishJobs
export const selectPublishJobsPagination = (state: { company: { pagination: IJobListFilter } }) => state.company.pagination
export const selectCustomUrlCurrentStep = (state: { company: { customUrlCurrentStep: number } }) => state.company.customUrlCurrentStep
export const selectCnameData = (state: { company: { cnameData: ICName[] } }) => state.company.cnameData