import { useNavigate, useParams } from 'react-router-dom';
import CompareCandidates from '../modules/createJob/components/Candidates/Comparison/CompareCandidates'
import Chart from '../modules/createJob/components/Candidates/Profile/Chart'
import { useGetCandidatesByIdsQuery } from '../modules/createJob/slice/candiddateApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectComparisonCandidates, setComparisonCandidates } from '../modules/createJob/slice/candidateSlice';
import LazyLoader from '../shared/components/LazyLoader/load';
import { useEffect } from 'react';
import { errorMessage } from '../shared/utils/constantData';
import { Warning } from '../shared/components/Warning/Warning';
import { Button } from 'antd';
import { getImageUrl } from '../shared/utils/functions/images';

export default function ComparePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { ids } = useParams()
    const comparisonCandidatesLength = useSelector(selectComparisonCandidates)?.length;
    const { data, isSuccess, isLoading, isError, isFetching } = useGetCandidatesByIdsQuery(ids, { skip: comparisonCandidatesLength });
    useEffect(() => {
        if (isSuccess) dispatch(setComparisonCandidates(data?.applications))
    }, [isSuccess, data, dispatch])
    return (
        <>
            {isLoading || isFetching ?
                <div className="flex items-center justify-center w-screen h-screen">
                    <div className='w-full max-w-2xl mb-4'>
                        <LazyLoader />
                        <p className='mt-4 text-base font-normal text-indigo-600'>Retrieving candidates data..</p>
                    </div>
                </div>
                : isSuccess || comparisonCandidatesLength ?
                    <div className='block md:flex flex-nowrap'>
                        <div className='grow-[3]'>
                            <Button className="text-indigo-700 border-0 shadow-none pt-7 pb-2.5 px-7 btn-md min-w-max" onClick={() => navigate(-1)}>
                                <img src={getImageUrl('blueLeftArrow')} className="inline w-4 mr-2 btn-icon" alt="back" />
                                <span className="inline">Back</span>
                            </Button>
                            <Chart />
                        </div>
                        <div className='grow-[2] max-w-[35rem]'><CompareCandidates /></div>
                    </div>
                    : isError ?
                        <Warning className='pt-24' title={errorMessage} message='Please refresh to try again' btnText='Refresh' />
                        : null}
        </>
    )
}
