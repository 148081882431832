import OperationStack from '../../../../shared/components/Layout/OperationStack'
import { Divider, Spin } from 'antd'
import NotificationItem from './NotificationItem';
import useAutomatedActionNotification from '../../../../shared/utils/customHooks/useAutomatedActionNotifications';
import { EmptyList } from '../../../../shared/components/List/EmptyList';
import { LoadingOutlined } from '@ant-design/icons';

export default function AutomatedActionsNotifications() {
    const { notifications, successCount, errorsCount, isComplete, isLoading } = useAutomatedActionNotification()

    return (
        <OperationStack
            title={"Sending automated messages"}
            errorTitle={errorsCount ? `${errorsCount} messages need your attention` : null}
            count={<b>{`${successCount}/${notifications?.length}`}</b>}
            withCloseBtn={isComplete}
            operations={
                isLoading ? <Spin indicator={<LoadingOutlined spin />} size='small' className='flex items-center justify-center py-3 center' />
                    : notifications.length && !isLoading ?
                    notifications?.map((operation, index) => (
                        <>
                            <NotificationItem automatedNotification={operation} key={operation.id} />
                            {index !== notifications.length - 1 && <Divider className="m-0" />}
                        </>
                    ))
                        : !isLoading && !notifications.length ? <EmptyList title='There are no automated messages' /> : null
            }
        />
    );
}
