import { IAutomatedNotification, ISearchCandidate, ISearchCandidateSFilter } from './../../../shared/utils/interfaces/candidatesListFace';
import { createSlice } from "@reduxjs/toolkit"
import { IAIFilter, ICandidate, ICandidatesFilter, IHiringStage, currentTypes } from "../../../shared/utils/interfaces/candidatesListFace";
import { IMentionMember } from "../../../shared/utils/interfaces/AuthInterface";
import { AISearchPerPage, candidatesPerPage, SearchcandidatesPerPage } from "../../../shared/utils/constantData";

const candidatesSlice = createSlice({
    name: 'candidates',
    initialState: {
        data: [],
        filter: {
            page: 1,
            perPage: candidatesPerPage,
            sortBy: 'az',
        },
        candidateProfile: {},
        candidateCv: '',
        currentHiringStages: [],
        otherHiringStages: [],
        dndStagesList: [],
        membersCount: 0,
        mentionableMembers: [],
        currentType: '',
        isComparing: false,
        comparisonCandidates: [],
        aiFiltersArray: [],
        searchOpen: false,
        isFetchingAiSearch: false,
        aiSearchSampleQuestions: [],
        activeFilterTabIndex: 0,
        aiSearchFilter: {
            page: 1,
            perPage: AISearchPerPage,
            searchMethodId: 1
        },
        isRemovingAiFilterTab: false,
        isSendEmailOpen: false,
        hasRunningAutomation: false,
        automateActionsNotifications: [],
        isCandidateModalOpen: false,
        searchCandidatesFilter: {
            page: 1,
            perPage: SearchcandidatesPerPage,
            sortBy: 'bestmatch',

        },
        searchSelectedJobsFilter: [],
        searchSelectedStagesFilter: [],
        searchCandidates: [],
        totalCandidatesCount: 0,
        isSubmittingSearch: false,
        isLoadingSearch: false,
        isErrorSearch: false

    },
    reducers: {
        setCandidates: (state, action) => {
            state.data = action.payload
        },
        setTotalCandidatesCount: (state, action) => {
            state.totalCandidatesCount = action.payload
        },
        setCandidatesFilter: (state, action) => {
            state.filter = action.payload;
        },
        setCandidateCv: (state, action) => {
            state.candidateCv = action.payload;
        },
        setCurrentType: (state, action) => {
            state.currentType = action.payload
        },
        setCandidateProfile: (state, action) => {
            state.candidateProfile = action.payload
        },
        setCurrentHiringStages: (state, action) => {
            state.currentHiringStages = action.payload;
        },
        setOtherHiringStages: (state, action) => {
            state.otherHiringStages = action.payload;
        },
        setDndStagesList: (state, action) => {
            state.dndStagesList = action.payload
        },
        setMembersCount: (state, action) => {
            state.membersCount = action.payload
        },
        setMentions: (state, action) => {
            state.mentionableMembers = action.payload
        },
        setIsComparing: (state, action) => {
            state.isComparing = action.payload
        },
        setComparisonCandidates: (state, action) => {
            state.comparisonCandidates = action.payload
        },
        setAiFiltersArray: (state, action) => {
            state.aiFiltersArray = action.payload
        },
        setSearchOpen: (state, action) => {
            state.searchOpen = action.payload
        },
        setIsFetchingAiSearch: (state, action) => {
            state.isFetchingAiSearch = action.payload
        },
        setAiSearchSampleQuestions: (state, action) => {
            state.aiSearchSampleQuestions = action.payload
        },
        setActiveFilterTabIndex: (state, action) => {
            state.activeFilterTabIndex = action.payload
        },
        setAiSearchFilter: (state, action) => {
            state.aiSearchFilter = action.payload;
        },
        setIsRemovingAiFilterTab: (state, action) => {
            state.isRemovingAiFilterTab = action.payload;
        },
        setIsSendEmailOpen: (state, action) => {
            state.isSendEmailOpen = action.payload;
        },
        setHasRunningAutomation: (state, action) => {
            state.hasRunningAutomation = action.payload;
        },
        setAutomatedActionNotifications: (state, action) => {
            state.automateActionsNotifications = action.payload;
        },
        setCandidatesModalOpen: (state, action) => {
            state.isCandidateModalOpen = action.payload
        },
        setSearchCandidatesFilter: (state, action) => {
            state.searchCandidatesFilter = action.payload
        },
        setSearchCandidates: (state, action) => {
            state.searchCandidates = action.payload
        },
        setSelectedSearchJobsFilter: (state, action) => {
            state.searchSelectedJobsFilter = action.payload
        },
        setSelectedSearchStagesFilter: (state, action) => {
            state.searchSelectedStagesFilter = action.payload
        },
        setIsSubmittingSearch: (state, action) => {
            state.isSubmittingSearch = action.payload
        },
        setIsLoadingSearch: (state, action) => {
            state.isLoadingSearch = action.payload
        },
        setIsErrorSearch: (state, action) => {
            state.isErrorSearch = action.payload
        },
    },
})

export const { setAutomatedActionNotifications, setDndStagesList, setCandidates, setCandidatesFilter, setCandidateCv, setCandidateProfile, setCurrentHiringStages, setOtherHiringStages, setHasRunningAutomation, setCandidatesModalOpen,setTotalCandidatesCount,
    setMembersCount, setMentions, setCurrentType, setIsComparing, setComparisonCandidates, setAiFiltersArray, setSearchOpen, setAiSearchSampleQuestions, setActiveFilterTabIndex, setAiSearchFilter, setIsFetchingAiSearch, setIsRemovingAiFilterTab, setIsSendEmailOpen, setSearchCandidatesFilter, setSearchCandidates, setSelectedSearchJobsFilter, setSelectedSearchStagesFilter, setIsSubmittingSearch ,setIsErrorSearch,setIsLoadingSearch} = candidatesSlice.actions

export default candidatesSlice.reducer

export const selectCandidates = (state: { candidates: { data: ICandidate[] } }) => state.candidates.data;
export const selectCandidatesFilters = (state: { candidates: { filter: ICandidatesFilter } }) => state.candidates.filter;
export const selectcurrType = (state: { candidates: { currentType: currentTypes } }) => state.candidates.currentType;
export const selectCandidateProfile = (state: { candidates: { candidateProfile: ICandidate } }) => state.candidates.candidateProfile;
export const selectCandidateCv = (state: { candidates: { candidateCv: string } }) => state.candidates.candidateCv;
export const selectCurrentHiringStages = (state: { candidates: { currentHiringStages: IHiringStage[] } }) => state.candidates.currentHiringStages;
export const selectOtherHiringStages = (state: { candidates: { otherHiringStages: IHiringStage[] } }) => state.candidates.otherHiringStages;
export const selectDndStagesList = (state: { candidates: { dndStagesList: string[] } }) => state.candidates.dndStagesList;
export const selectMembersCount = (state: { candidates: { membersCount: number } }) => state.candidates.membersCount;
export const selectMentions = (state: { candidates: { mentionableMembers: IMentionMember[] } }) => state.candidates.mentionableMembers;
export const selectIsComparing = (state: { candidates: { isComparing: boolean } }) => state.candidates.isComparing;
export const selectComparisonCandidates = (state: { candidates: { comparisonCandidates: ICandidate[] } }) => state.candidates.comparisonCandidates;
export const selectAiFiltersArray = (state: { candidates: { aiFiltersArray: string[] } }) => state.candidates.aiFiltersArray;
export const selectSearchOpen = (state: { candidates: { searchOpen: boolean } }) => state.candidates.searchOpen;
export const selectIsFetchingAiSearch = (state: { candidates: { isFetchingAiSearch: boolean } }) => state.candidates.isFetchingAiSearch;
export const selectAiSearchSampleQuestions = (state: { candidates: { aiSearchSampleQuestions: string[] } }) => state.candidates.aiSearchSampleQuestions;
export const selectActiveTabFilterIndex = (state: { candidates: { activeFilterTabIndex: number } }) => state.candidates.activeFilterTabIndex;
export const selectAiFilters = (state: { candidates: { aiSearchFilter: IAIFilter } }) => state.candidates.aiSearchFilter;
export const selectIsRemovingAiFilterTab = (state: { candidates: { isRemovingAiFilterTab: boolean } }) => state.candidates.isRemovingAiFilterTab;
export const selectIsSendEmailOpen = (state: { candidates: { isSendEmailOpen: boolean } }) => state.candidates.isSendEmailOpen;
export const selectHasRunningAutomation = (state: { candidates: { hasRunningAutomation: boolean } }) => state.candidates?.hasRunningAutomation
export const selectAutomatedActionsNotifications = (state: { candidates: { automateActionsNotifications: IAutomatedNotification[] } }) => state.candidates.automateActionsNotifications
export const selectIsCandidateModalOpen = (state: { candidates: { isCandidateModalOpen: boolean } }) => state.candidates.isCandidateModalOpen
export const selectSearchCandidatesFilter = (state: { candidates: { searchCandidatesFilter: ISearchCandidateSFilter } }) => state.candidates.searchCandidatesFilter
export const selectSearchCandidates = (state: { candidates: { searchCandidates: ISearchCandidate[] } }) => state.candidates.searchCandidates
export const selectSelectedSearchJobsFilter = (state: { candidates: { searchSelectedJobsFilter: string[] } }) => state.candidates.searchSelectedJobsFilter
export const selectSelectedSearchStagesFilter = (state: { candidates: { searchSelectedStagesFilter: string[] } }) => state.candidates.searchSelectedStagesFilter
export const selectTotalCandidatesCount = (state: { candidates: { totalCandidatesCount: number } }) => state.candidates.totalCandidatesCount;
export const selectIsSubmittingSearch = (state: { candidates: { isSubmittingSearch: number } }) => state.candidates.isSubmittingSearch;
export const selectIsLoadingSearch = (state: { candidates: { isLoadingSearch: number } }) => state.candidates.isLoadingSearch;
export const selectIsErrorSearch = (state: { candidates: { isErrorSearch: number } }) => state.candidates.isErrorSearch;