import { ReactNode, useEffect } from 'react';
import checkCircleGray from '../../../../shared/assets/images/checkCircleGray.svg';
import checkCircle from '../../../../shared/assets/images/checkCircle.svg';
import Exclamation from '../../../../shared/assets/images/Exclamation.svg';
// import Refresh from '../../../../shared/assets/images/Refresh.svg';
import closeIcon from '../../../../shared/assets/images/CloseIcon.svg';
import { automatedActionStatus } from '../../../../shared/utils/interfaces/settingsInterface';
import { Button, message, Spin } from 'antd';
import useTimer from '../../../../shared/utils/customHooks/useTimer';
import { IAutomatedNotification } from '../../../../shared/utils/interfaces/candidatesListFace';
import { useDispatch, useSelector } from 'react-redux';
import { selectAutomatedActionsNotifications, setAutomatedActionNotifications } from '../../slice/candidateSlice';
import { useCancelEmailMutation } from '../../slice/candiddateApiSlice';
import { apiStatuses, errorMessage } from '../../../../shared/utils/constantData';
import { LoadingOutlined } from '@ant-design/icons';

export default function NotificationItem({ automatedNotification: { status, id: notificationId, leftTime, statusReason } }: { automatedNotification: IAutomatedNotification }) {
    const timer = useTimer(leftTime);
    const dispatch = useDispatch()
    const notifications = useSelector(selectAutomatedActionsNotifications)

    const [cancelEmail, { isSuccess, isLoading, isError, error }] = useCancelEmailMutation()

    useEffect(() => {
        if (isSuccess) {
            dispatch(setAutomatedActionNotifications(notifications.filter(({ id }) => notificationId !== id)))
        } else if (isError) {
            message.error(error.data?.statusCode === apiStatuses.serverError ? errorMessage : error.data?.message)
        }
        // eslint-disable-next-line
    }, [isSuccess, isError])

    const handleCancelEmail = async () => {
        await cancelEmail(notificationId)
    }
    const statusIcon: { [key: string]: string } = {
        [automatedActionStatus.pending]: checkCircleGray,
        [automatedActionStatus.done]: checkCircle,
        [automatedActionStatus.failed]: Exclamation
    }

    const statusBtn: { [key: string]: () => ReactNode } = {
        [automatedActionStatus.processing]: () => leftTime ? <Button onClick={handleCancelEmail}
            className='p-0 min-w-max btn-link ms-2 h-fit' > {isLoading ? <Spin size="small" /> : <img className="w-4 mb-2" src={closeIcon} alt='cancel' />}</Button > : <></>,
        [automatedActionStatus.pending]: () => leftTime ? <Button onClick={handleCancelEmail}
            className='p-0 min-w-max btn-link ms-2 h-fit' > {isLoading ? <Spin size="small" /> : <img className="w-4" src={closeIcon} alt='cancel' />}</Button > : <></>,
        [automatedActionStatus.done]: () => <></>,
        [automatedActionStatus.failed]: () => <></>,
        // [automatedActionStatus.failed]: () => <Button className='p-0 min-w-max btn-link ms-2 h-fit'><img src={Refresh} alt='Retry' /></Button>,
    }

    return (
        <div className='py-2.5 flex flex-nowrap justify-between items-center'>
            <div className='flex flex-nowrap'>
                {status === automatedActionStatus.processing ? <Spin size='small' className='mt-1 text-gray-600 me-2' indicator={<LoadingOutlined spin />} /> : <img src={statusIcon[status]} alt={status} className='inline me-2' />}
                <div>
                    <span className='text-gray-600'>{statusReason}</span>
                    {status === automatedActionStatus.pending && leftTime ? <span className='font-semibold text-gray-600 ms-1'>{timer}</span> : <></>}
                </div>
            </div>
            {statusBtn[status]()}
        </div>
    )
}
