import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        plansModalOpen: false,
        templatePreviewOpen: false,
    },
    reducers: {
        setPlansModalOpen: (state, action: { payload: boolean }) => {
            state.plansModalOpen = action.payload
        },
        setTemplatePreviewOpen: (state, action: { payload: boolean }) => {
            state.templatePreviewOpen = action.payload
        },
    }
})

export const { setPlansModalOpen, setTemplatePreviewOpen } = settingsSlice.actions;

export default settingsSlice.reducer;

export const selectPlansModalOpen = (state: { settings: { plansModalOpen: boolean } }) => state.settings.plansModalOpen
export const selectTemplatePreviewOpen = (state: { settings: { templatePreviewOpen: boolean } }) => state.settings.templatePreviewOpen
