import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useSelector } from "react-redux";
import { selectCandidateCv } from "../../../modules/createJob/slice/candidateSlice";
import { Toolbar } from "./Toolbar";
import { Warning } from "../Warning/Warning";
import { useEffect } from "react";
import { analytics } from "../../utils/analytics";
import { logEvent } from "firebase/analytics";
import './cvViewer.scss'
import "@cyntler/react-doc-viewer/dist/index.css";

export function CVViewer({ docViewerRef }: { docViewerRef: any }) {
    const candidateCv = useSelector(selectCandidateCv)
    useEffect(() => {
        logEvent(analytics, 'Cv screen');
    }, [])
    const docs = [
        {
            uri: candidateCv,
            fileType: candidateCv.split('.').pop()
        }
    ];

    return (
        <>
            {candidateCv ?
                <DocViewer
                    language="en"
                    ref={docViewerRef}
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    theme={{
                        disableThemeScrollbar: true,
                    }}
                    config={{
                        header: {
                            overrideComponent: Toolbar,
                        },
                        pdfZoom: {
                            defaultZoom: 1.1,
                            zoomJump: 0.2,
                        },
                        pdfVerticalScrollByDefault: true,
                    }}
                />
                : <Warning title='Something went wrong' message='Failed loading documents, please refresh' />}
        </>
    )
}